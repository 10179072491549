import {TaskColumnSettings} from "./TaskColumnSettings";
import {InputSelectField, InputSelectType} from "../../forms/InputSelectField";
import {motion} from "framer-motion";
import {SpringAnimation} from "./TaskAnimations";

export const TableCell = (props: TableCellProps) => {

    const {visible, onClick, classes, value, style, join} = props;

    return (
        <motion.div
            {...SpringAnimation}
            className={"column-visible table-cell" + (visible ? "" : " column-hidden")}
            onClick={onClick}
            style={{...style, alignItems: "center", display: "grid"}}
        >
            <div className="">
                {join ?
                    value.join(", ")
                    : value.map((item, index) => {
                        return (
                            <div key={index}
                                 className={"me-2 " + (typeof classes === "string" ? classes : classes ? classes[index] : "")}
                                // className={"overflow-hidden me-2 no-wrap " + (typeof classes === "string" ? classes : classes ? classes[index] : "")}
                            >{item}</div>
                        )
                    })}
            </div>
        </motion.div>
    )
}

export const CompletedCell = (props: TableCellProps) => {

    const {visible, onClick, value, style, id} = props;

    return (
        /* Completed */
        <motion.div
            {...SpringAnimation}
            onClick={() => {
            }}
            className={"column-visible center-vertically " + (visible ? "" : " column-hidden")}
            style={style}
        >
            <div style={{fontSize: "1rem", paddingLeft: "4px"}}>
                <InputSelectField
                    value={value}
                    handleAction={() => onClick(id)}
                    type={InputSelectType.checkbox}
                    className="overflow-hidden no-wrap row-height checkbox-custom"
                />
            </div>
        </motion.div>
    )

}

class TableCellProps {
    visible: boolean;
    value: string[];
    style: any;
    onClick?: any = () => {
    };
    classes?: string[] | string = "";
    id?: number;
    join?: boolean = false;
}

export const style = (settings: TaskColumnSettings | undefined) => {
    if (!settings) return {};
    return {
        width: settings.width,
        minWidth: settings.minWidth,
        textAlign: settings.align,
    };
}
